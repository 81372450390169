const heroWithSubNav = {

    init: function () {
        const subNavButton = document.querySelector('.sub-nav-activator');
        const subNavMenu = document.querySelector('.sub-nav-list');

        if (subNavButton == null || subNavMenu == null) {
            return;
        }

        document.addEventListener("click", (e) => {
            if (!e.target.closest(".hero-with-sub-navigation__nav")) {
                subNavButton.classList.remove("selected");
                subNavButton.setAttribute("aria-expanded", "false");
                subNavMenu.classList.remove("open");

                try {
                    document.querySelector('.level-1.open').classList.remove("open");
                } catch (e) {
                }
            }
        });

        document.addEventListener("click", (e) => {
            if (Array.from(event.target.classList).indexOf('sub-nav-activator') > -1) {
                const aria = e.target.getAttribute("aria-expanded") === "true" ? "false" : "true";
                e.target.classList.toggle("selected");
                e.target.setAttribute("aria-expanded", aria);
                e.target.parentElement.nextElementSibling.classList.toggle("open");
            }
        });

        document.addEventListener("click", (e) => {
            if (Array.from(event.target.classList).indexOf('sub-nav-toggle') > -1) {
                const aria = e.target.getAttribute("aria-expanded") === "true" ? "false" : "true";
                e.target.classList.toggle("selected");
                e.target.setAttribute("aria-expanded", aria);
                e.target.parentElement.nextElementSibling.hidden = !e.target.parentElement.nextElementSibling.hidden;
            }
        });

    }
};

window.addEventListener("DOMContentLoaded", (e) => {
	heroWithSubNav.init();
});