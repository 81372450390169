import Language from '../Language'

export default new Language(
  'Macedonian',
  ['Јануари', 'Февруари', 'Март', 'Април', 'Мај', 'Јуни', 'Јули', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември'],
  ['Јан', 'Фев', 'Мар', 'Апр', 'Мај', 'Јун', 'Јул', 'Авг', 'Сеп', 'Окт', 'Ное', 'Дек'],
  ['Нед', 'Пон', 'Вто', 'Сре', 'Чет', 'Пет', 'Саб']
)

  ;
