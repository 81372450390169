window.addEventListener("DOMContentLoaded", () => {

    const lazyVideos = [].slice.call(document.querySelectorAll(".hero-video video.lazy"));
    const allVideos = document.querySelectorAll(".hero-video video");
    const allSoundButtons = document.querySelectorAll(".hero-video .hero-video__sound-button");
    const heroFrontpage = document.querySelector('[data-hero-frontpage]');
    const clickElems = heroFrontpage?.querySelectorAll('[data-click="button"]');

    clickElems?.forEach(elem => {
        elem.addEventListener('click', () => {
            const parent = elem.closest('[data-content]');
            const btnCon = parent?.querySelector('[data-button]');
            const btn = btnCon?.querySelector('button, a');

            btn?.click();
        });
    });

    if ("IntersectionObserver" in window) {
        var lazyVideoObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((video) => {
                if (video.isIntersecting) {
                    for (let source in video.target.children) {
                        const videoSource = video.target.children[source];
                        if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                            const desktopSrc = videoSource.dataset.src;
                            const mobileSrc = videoSource.dataset.mobilesrc;
                            const showVideoInModal = videoSource.dataset.showinmodal;
                            const playVideoOnMobile = videoSource.dataset.playvideoonmobile;

                            if (window.matchMedia("(max-width: 1100px)").matches) {
                                if (playVideoOnMobile === "true" && showVideoInModal === "false") {
                                    videoSource.src = mobileSrc;
                                }
                            } else {
                                videoSource.src = desktopSrc;
                            }
                            
                            videoSource.removeAttribute('data-src');
                            videoSource.removeAttribute('data-mobilesrc');
                        }
                    }

                    video.target.load();
                    video.target.classList.remove("lazy");
                    video.target.classList.remove("loading");
                    video.target.classList.add("lazyloaded");
                    lazyVideoObserver.unobserve(video.target);

                }
            });
        });

        lazyVideos.forEach((lazyVideo) => {
            lazyVideoObserver.observe(lazyVideo);
        });

    }

    function screenTest(e) {
        if (e.matches) {
            // console.log("Over 1100");
        } else {
            // console.log("UNDER 1100");
            allVideos.forEach((video) => {
                video.muted = true;
            });
            allSoundButtons.forEach((but) => {
                but.classList.remove("on");
            });

        }
    }

    const mediaQueryList = window.matchMedia("(min-width: 1100px)");
    mediaQueryList.addListener(screenTest);
    screenTest(mediaQueryList);

    document.addEventListener("click", (e) => {
        if (e.target.classList.contains("hero-video__sound-button")) {
            const vidContainer = e.target.closest(".inline-hero-background");
            const vid = vidContainer.querySelector("video");

            if (vid) {
                vid.muted = !vid.muted;
                e.target.classList.toggle("on");
            }
        }
    });

});