import Language from '../Language'

export default new Language(
  'Polish',
  ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
  ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
  ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob']
)

  ;
