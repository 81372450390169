import Language from '../Language'

export default new Language(
  'Slovakian',
  ['január', 'február', 'marec', 'apríl', 'máj', 'jún', 'júl', 'august', 'september', 'október', 'november', 'december'],
  ['jan', 'feb', 'mar', 'apr', 'máj', 'jún', 'júl', 'aug', 'sep', 'okt', 'nov', 'dec'],
  ['ne', 'po', 'ut', 'st', 'št', 'pi', 'so']
)

  ;
