import Cookies from 'js-cookie';

window.addEventListener("DOMContentLoaded", () => {

    var accessoCart = (function() {
        var quantity = null;
        var cartAlertTimer;
        var $cartButtons = null;
        var $quantity = null;
        var snoozeWiggleTime = 10000;// How long before the button starts to wiggle
        var checkAccessoCountTimer = 1000; // How often to look for changes in accesso
        

        function init() {

            //if ($('.utility--cart').data('enablenotify') === false) {
            //    return;
            //}

            $cartButtons = $('[data-accesso-launch="checkout"], .accessible-megamenu-toggle');
            if ($cartButtons.length === 0)
                return;

            $quantity = $('[data-accesso-quantity]');


            $cartButtons.on('click', function () {
                cartAlertTimer = clearTimeout(cartAlertTimer); //clear timer on re-open?
            });

            $(document).on('click', '.accesso-close', function () { //future binding. accesso-close may not exist on init
                if (quantity !== "undefined" && quantity > 0) {
                    hasUnfinishedFlow();
                }
            });

            testForQuantity();
        }

        function hasUnfinishedFlow() {
            cartAlertTimer = setTimeout(wiggle, snoozeWiggleTime);
        }

        function testForQuantity() {
            setInterval(function () {

                var accessoQuantity = parseInt($quantity.text(), 10);

                if (!isNaN(accessoQuantity) && accessoQuantity !== quantity) {
                    quantity = accessoQuantity;

                    if (quantity === 0) {
                        $cartButtons.addClass('cart-empty');
                    } else {
                        hasUnfinishedFlow();
                        $cartButtons.removeClass('cart-empty');
                    }
                } else {
                    return;
                }

            }, checkAccessoCountTimer);
        }

        function wiggle() {
            if (Cookies.get("AccessoCartWasNotified") === "true")
                return;

            $cartButtons.addClass('wiggle');
            setTimeout(function () {
                $cartButtons.removeClass("wiggle");
            }, 5000);
            Cookies.set('AccessoCartWasNotified', 'true', { expires: 3 * 60 });
        }

        return {
            init:init
        }
    })();

    accessoCart.init();


});