import Language from '../Language'

export default new Language(
  'Italian',
  ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
  ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
  ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab']
)

  ;
