import tippy from 'tippy.js';
import { hideAll } from 'tippy.js';
import Vue from 'vue';
import PasstypeRestrictionDates from '../../components/passtype-restriction-dates/PasstypeRestrictionDates.vue';

const pageHasEntryRestrictionDatesMap = document.querySelector("#EntryRestrictionDatesMap") != null;

if (pageHasEntryRestrictionDatesMap) {
    document.querySelectorAll(".passholder-exclusion-dates-page-wrapper").forEach(function (element) {
        new Vue({
            el: element,
            components: {
                'passtype-restrictions-dates': PasstypeRestrictionDates
            }
        });
    })
}