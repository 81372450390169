import Language from '../Language'

export default new Language(
  'Faroese',
  ['Januar', 'Februar', 'Mars', 'Apríl', 'Mai', 'Juni', 'Juli', 'August', 'Septembur', 'Oktobur', 'Novembur', 'Desembur'],
  ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
  ['Sun', 'Mán', 'Týs', 'Mik', 'Hós', 'Frí', 'Ley']
)

  ;
