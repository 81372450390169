import Language from '../Language'

export default new Language(
  'Serbian in Cyrillic script',
  ['Јануар', 'Фебруар', 'Март', 'Април', 'Мај', 'Јун', 'Јул', 'Август', 'Септембар', 'Октобар', 'Новембар', 'Децембар'],
  ['Јан', 'Феб', 'Мар', 'Апр', 'Мај', 'Јун', 'Јул', 'Авг', 'Сеп', 'Окт', 'Нов', 'Дец'],
  ['Нед', 'Пон', 'Уто', 'Сре', 'Чет', 'Пет', 'Суб']
)

  ;
