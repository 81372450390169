
window.addEventListener("DOMContentLoaded", function () {
    const currentTakeover = document.querySelector('.takeover');
    const currentTakeoverOverlay = document.querySelector('.takeover-overlay');

    document.addEventListener("click", function (e) {

        //Remove the takeover from the DOM
        if (e.target.dataset && e.target.dataset.takeoverClose != undefined) {
            const overlay = document.querySelector('.takeover-overlay');
            const takeover = document.querySelector('.takeover');
            const modalGuid = takeover.dataset.modalGuid;
            const siteConfig = {};
            Object.assign(siteConfig, window.merlinConfig);

            if (currentTakeoverOverlay) {
                //Hack to prevent scrolling of body when modal is open pt2
                const scrollY = document.body.style.top;
                document.body.style.position = '';
                document.body.style.top = '';
                document.body.style.left = '';
                document.body.style.right = '';
                window.scrollTo({top: parseInt(scrollY || '0') * -1, left: 0, behavior: 'instant'});
            }

            if (overlay != undefined) {
                overlay.remove();
            }

            takeover.remove();

            let payload = {};
            payload.Guid = modalGuid;

            $.ajax({
                url: `${siteConfig.urlPrefix}/umbraco/api/modalcookie/set`,
                method: "POST",
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                data: JSON.stringify(payload)
            }).done(function (data) {
                //do nothing
            }).fail(function (data) {
                console.log("error", data);
            }).always(function () {
                //do nothing
            });

        }

        //Handle toggle states when the takeover is placed in either left or right corner
        if (e.target.dataset && e.target.dataset.toggleModalContent != undefined) {
            const globalCloseButton = document.querySelector('[data-takeover-close]');
            const takeover = document.querySelector('.takeover');

            if (e.target.dataset.toggleModalContent === "show") {
                e.target.parentNode.nextElementSibling.hidden = false;
                e.target.nextElementSibling.hidden = false;
                globalCloseButton.hidden = true;
                takeover.classList.add("active");
            } else {
                e.target.parentNode.nextElementSibling.hidden = true;
                e.target.previousElementSibling.hidden = false;
                globalCloseButton.hidden = false;
                takeover.classList.remove("active");
            }
            e.target.hidden = true;

        }

    });

    if (currentTakeover) {
        const delay = currentTakeover.dataset.delay;

        setTimeout(() => {
            //Hack to prevent scrolling of body when modal is open pt1 - https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
            if(document.querySelector('.CenteredTakeover')){
                document.body.style.top = `-${window.scrollY}px`;
                document.body.style.position = 'fixed';
                document.body.style.left = '0px';
                document.body.style.right = '0px';
            }
            currentTakeover.classList.add('isActive');
            if (currentTakeoverOverlay) {
                currentTakeoverOverlay.classList.add('isActive');
            }
        }, delay * 1000);
    }
});