const FilterDropdown = (function() {
	const allFilters = document.querySelectorAll("[data-filter]");

	function closeAllFilters() {
		allFilters.forEach(function(filter) {
			const activator = filter.querySelector("[data-filter-activator]");
			const modal = filter.querySelector("[data-filter-modal]");
			if (modal) {
				modal.style.display = "none";
			}
			if (activator) {
				activator.setAttribute("aria-expanded", "false");
				activator.closed = true;
			}
		});
	}

	allFilters.forEach(function(filter) {
		const activator = filter.querySelector("[data-filter-activator]");

		const modal = filter.querySelector("[data-filter-modal]");

		if (activator) activator.closed = true;

		if (activator)
			activator.addEventListener("click", function(e) {
				if (this.closed) {
					closeAllFilters();
				}

				modal.style.display = this.closed ? "block" : "none";
				activator.setAttribute("aria-expanded", this.closed.toString());

				this.closed = !this.closed;
			});
	});

	document.addEventListener("click", function(e) {
		if (!e.target.closest("[data-filter]")) {
			closeAllFilters();
		}
	});
})();
