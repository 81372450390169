window.addEventListener("DOMContentLoaded", () => {
    document.body.addEventListener("click", function (event) {
        if (event.target.dataset.mobileAdditionalInformationButton) {
            const key = event.target.dataset.mobileAdditionalInformationButton;
            const query = `[data-mobile-additional-information-text="${key}"]`;
            document.querySelector(query).hidden = !document.querySelector(query).hidden;
            event.target.classList.toggle("active");
        }

        if (event.target.dataset.desktopAdditionalInformationButton) {
            const key = event.target.dataset.desktopAdditionalInformationButton;
            const query = `[data-desktop-additional-information-text="${key}"]`;
            document.querySelector(query).hidden = !document.querySelector(query).hidden;
            event.target.classList.toggle("active");
        }

        if (event.target.dataset.additionalInformationButton) {
            const key = event.target.dataset.additionalInformationButton;
            const query = `[data-additional-information-text="${key}"]`;
            document.querySelector(query).hidden = !document.querySelector(query).hidden;
            event.target.classList.toggle("active");
        }
    });
});
