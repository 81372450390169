import MenuButton from 'inclusive-menu-button';

window.addEventListener("DOMContentLoaded", () => {
    var buttons = document.querySelectorAll("button.page-footer__link-list-button");

    function toggle(event) {
        var isExpanded = JSON.parse(event.target.getAttribute("aria-expanded"));
        isExpanded = !isExpanded;
        event.target.setAttribute("aria-expanded", isExpanded.toString());
        event.target.nextElementSibling.style.display = isExpanded ? "block" : "none";
    }

    buttons.forEach(function(button) {
        button.addEventListener("click", toggle);
    });

});

function generateBrandLinksMarkup(inputData) {

    const data = JSON.parse(inputData);
    let headline = '';
    let links = '';

    if (data.Headline) {

        headline = data.Headline;
    }

    if (data.Links && data.Links.length > 0) {

        let first = true;
        let separator = "";
        data.Links.forEach(function (link) {

            if (!first)
                separator = "&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;";

            links += `${separator}<a class="link-button" href="${link.Url}" target="${link.Target}" rel="noopener noreferrer">${link.Label}</a>`;

            first = false;

        });


        links = `<p>${links}</p>`;
    }

    return `<div class="brand-links__generated-content__output">
				<h3>${headline}</h3>
				${links}
			</div>`;

}

window.addEventListener("DOMContentLoaded", () => {

    // Instantiation
    var allBrandLinkButtons = document.querySelectorAll('footer [data-inclusive-menu-opens]');
    var brandLinksOutputDesktop = document.getElementById("brand-links-output-desktop");
    var brandLinksOutputMobile = document.getElementById("brand-links-output-mobile");


    function closeAllDropDowns() {
        allBrandLinkButtons.forEach(function(button) {
            button.classList.remove("direction-up");
            button.classList.remove("direction-down");
            button.classList.remove("direction-left");
            button.classList.remove("direction-right");
        });
    }

    allBrandLinkButtons.forEach(function (button) {
        var menuButton = new MenuButton(button);
        menuButton.on('choose', function (choice) {
            const span = menuButton.button.querySelector(".brand-links-dropdown__button__text");
            span.innerText = choice.textContent;
            brandLinksOutputDesktop.innerHTML = generateBrandLinksMarkup(choice.value);
            brandLinksOutputMobile.innerHTML = generateBrandLinksMarkup(choice.value);
        });

        button.addEventListener("click", function(event) {
            const dd = event.target.nextElementSibling;
            var isOut = isOutOfViewport(dd);

            //Also take into account that there may be a bookingbar at the bottom om the page
            var bookingBar = document.querySelectorAll(".hex-footer-booking-bar-wrapper, .booking-container");
            var bookingBarCheck = {};
            bookingBarCheck.bottom = false;

            var bookingBarBounding = dd.getBoundingClientRect();

            if (bookingBar && bookingBar.length > 0) {
                bookingBarCheck.bottom = (bookingBarBounding.bottom + bookingBar[0].clientHeight) > (window.innerHeight || document.documentElement.clientHeight);
            }

            if (isOut.top) {
                // Top is out of viewport
                button.classList.add("direction-down");
            }

            if (isOut.left) {
                // Left side is out of viewport
                button.classList.add("direction-left");
            }

            if (isOut.bottom || bookingBarCheck.bottom) {
                // Bottom is out of viewport
                button.classList.add("direction-up");

            }

            if (isOut.right) {
                // Right side is out of viewport
                button.classList.add("direction-right");
            }

        });
    });


    document.addEventListener("click", function (e) {
        if (!e.target.closest("[data-inclusive-menu]")) {
            closeAllDropDowns();
        }
    });

});