import Language from '../Language'

export default new Language(
  'Latvian',
  ['Janvāris', 'Februāris', 'Marts', 'Aprīlis', 'Maijs', 'Jūnijs', 'Jūlijs', 'Augusts', 'Septembris', 'Oktobris', 'Novembris', 'Decembris'],
  ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jūn', 'Jūl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
  ['Sv', 'Pr', 'Ot', 'Tr', 'Ce', 'Pk', 'Se']
)

  ;
