import Language from '../Language'

export default new Language(
  'Bosnian',
  ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Juni', 'Juli', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'],
  ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
  ['Ned', 'Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub']
)

  ;
