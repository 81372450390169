window.addEventListener("DOMContentLoaded", function (event) {
    const addZero = (x) => (x < 10 && x >= 0) ? "0" + x : x;

    function secondsToDhms(seconds) {
        seconds = Number(seconds);
        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor(seconds % (3600 * 24) / 3600);
        const m = Math.floor(seconds % 3600 / 60);
        const s = Math.floor(seconds % 60);

        const dDisplay = d;
        const hDisplay = h;
        const mDisplay = m;
        const sDisplay = s;

        return { days: dDisplay, hours: hDisplay, minutes: mDisplay, seconds: sDisplay };
    }

    const countDowns = document.querySelectorAll('.countdown');

    const updateTime = function () {
        countDowns.forEach(function (countdown) {
            let timer;

            const individualTimer = function() {
                const secondsInput = parseInt(countdown.dataset.seconds);
                const redirectUrl = countdown.dataset.redirecturl;
                const { days, hours, minutes, seconds } = secondsToDhms(secondsInput);

                if (secondsInput > 0) {
                    countdown.querySelector(".day .num").innerHTML = addZero(days);
                    countdown.querySelector(".hour .num").innerHTML = addZero(hours);
                    countdown.querySelector(".min .num").innerHTML = addZero(minutes);
                    countdown.querySelector(".sec .num").innerHTML = addZero(seconds);
                    countdown.dataset.seconds = secondsInput - 1;
                } else {
                    if(redirectUrl == '' || redirectUrl == undefined) {
                        const modal = countdown.closest('.takeover');
                        const modalCloseBtn = modal?.querySelector('[data-takeover-close]');
                        modalCloseBtn?.click();
                        clearInterval(timer);
                    } else {
                        window.location.href = redirectUrl;
                    }
                }
            }
            
            timer = setInterval(individualTimer, 1000);
        });
    }

    updateTime();
});