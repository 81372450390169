import Language from '../Language'

export default new Language(
  'Ukraine',
  ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
  ['Січ', 'Лют', 'Бер', 'Квіт', 'Трав', 'Чер', 'Лип', 'Серп', 'Вер', 'Жовт', 'Лист', 'Груд'],
  ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
)

  ;
