document.addEventListener("DOMContentLoaded", () => {

    var hexBooking = (function () {

        function init() {
            $(document).on('click', '.button-hex', function (e) {
                var venueCode = $(this).data('venue-code');
                window.sbsearchform({ venueCode: venueCode, inline:false }, document.getElementById('sbsearchform-modal'));
            });
        }

        return {
            init:init
        }
    })();
    hexBooking.init();
});