window.addEventListener("DOMContentLoaded", () => {


    let searchStateOpen = false;
    let searchLayerInitiatorElement = null;

    function elementExists(element) {
        return element !== null && element !== undefined;
    }

    const toggleSearchLayerButton = document.querySelector("[data-search-button]");
    const dataSearchLayer = document.querySelector("[data-search-layer]");
    const input = document.querySelector("[data-search-input]");

    if (elementExists(dataSearchLayer) && elementExists(toggleSearchLayerButton)) {

        function toggleSearchLayerState(e) {
            searchLayerInitiatorElement = e.target;
            searchStateOpen = !searchStateOpen;
            dataSearchLayer.hidden = !searchStateOpen;

            if (searchStateOpen) {
                input.focus();
            }
        }

        function closeSearchLayer() {
            searchStateOpen = false;
            dataSearchLayer.hidden = !searchStateOpen;
            try {
                searchLayerInitiatorElement.focus();
            } catch (e) {
            }
            searchLayerInitiatorElement = null;
        }

        document.addEventListener("keydown", (event) => {
            const code = event.which ? event.which : event.keyCode || 0;

            if (code === 27) { //ESC key
                closeSearchLayer();
            }
        });

        toggleSearchLayerButton.addEventListener("click", (e) => {
            e.stopPropagation();
            toggleSearchLayerState(e);
        });

        document.addEventListener("click", function (e) {
            if (e.target.closest(".search-layer") === null || e.target.classList.contains("search-layer__close-button")) {
                closeSearchLayer();
            }
        });

    }

});