const cookieService = {

    setCookie: function(name,value) {
        var d = new Date();
        d.setTime(d.getTime() + 12*60*60*1000);
        var expires = "expires=" + d.toGMTString();
        document.cookie = name+"="+value+"; "+expires;
    },
    getCookie: function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },
    eraseCookie: function (name) {   
        document.cookie = name+'=; Max-Age=-99999999;';  
    },
    callMe: function() {
        console.log('COOKIE SERVICE CALLED!!!!');
    }

};

export { cookieService };
