window.addEventListener("DOMContentLoaded", () => {

    const allPseudoMultiCtaButtons = document.querySelectorAll('a[data-multictabutton]');
    const allMultiCtaButtons = document.querySelectorAll('button[data-multictabutton]');

    if (allMultiCtaButtons.length <= 0) {
        return;
    }

    const multiCtaObject = {
        activeButton: null,
        activeContainer: null,
        getBoundingClientRects: function () {
            return {
                buttonElement: this.activeButton.getBoundingClientRect() ?? null,
                containerElement: this.activeContainer.getBoundingClientRect() ?? null
            };
        },
        getActiveButtonPosition: function () {
            if (this.activeButton !== null) {
                return this.activeButton.getBoundingClientRect();
            }
            return null;
        },
        getActiveContainerPosition: function () {
            if (this.activeButton !== null) {
                return this.activeContainer.getBoundingClientRect();
            }
            return null;
        },
        resetActiveMultiCtaButtons: function () {
            //const allMultiCtaButtons = document.querySelectorAll(".button--multicta");
            allMultiCtaButtons.forEach(function (button) {
                button.classList.remove("active");
            });

            this.activeButton = null;
        },
        closeMultiCtaContainers: function () {
            const allMultiCtaContainers = document.querySelectorAll(".multicta-container");
            allMultiCtaContainers.forEach(function (container) {
                container.hidden = true;
                container.removeAttribute("style");
            });

            if (this.activeButton) {
                this.activeButton.classList.remove("active");
                this.activeButton = null;
            }
            if (this.activeContainer) {
                this.activeContainer.hidden = true;
                this.activeContainer = null;
            }
        },
        updateActiveContainerPosition: function () {
            if (this.activeButton && this.activeContainer) {
                this.activeContainer.hidden = false;
                const buttonPos = this.getActiveButtonPosition();
                const containerPos = this.getActiveContainerPosition();


                //var scrollLeft = (window.pageXOffset !== undefined) ? window.pageXOffset : (document.documentElement || document.body.parentNode || document.body).scrollLeft;
                var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

                const windowHeight = window.innerHeight;

                if (buttonPos && buttonPos.top < (windowHeight / 2)) {
                    this.activeContainer.style.top = buttonPos.bottom + scrollTop + 60 + "px";
                    this.activeButton.classList.add('flipit');
                } else {
                    this.activeContainer.style.top = buttonPos.top + scrollTop - containerPos.height + "px";
                    this.activeButton.classList.remove('flipit');
                }
                
                

                let buttonCenterPosition = (buttonPos.width / 2) + buttonPos.left;

                let leftPos = buttonCenterPosition - (this.activeContainer.clientWidth / 2);

                if (leftPos < 0) {
                    leftPos = 0;
                }

                this.activeContainer.style.left = leftPos + "px";

                if (this.activeContainer.getBoundingClientRect().left > 0 &&
                    this.activeContainer.getBoundingClientRect().right < document.documentElement.clientWidth) {
                    return;
                }

                const windowWidth = document.documentElement.clientWidth;
                const windowCenter = windowWidth / 2;

                if ((buttonPos.left + (buttonPos.width/2)) > windowCenter) {
                    //console.log("Knappen er til højre for midten");

                    if ((windowWidth - buttonPos.right) < 20) {
                        this.activeContainer.style.left = "auto";
                        this.activeContainer.style.right = "0px";

                    }
                }


                if (window.matchMedia("(max-width: 450px)").matches) {
                    this.activeContainer.style.right = "auto";
                    this.activeContainer.style.left = "0px";
                }


                if (this.activeContainer.getBoundingClientRect().right > document.documentElement.clientWidth && (buttonPos.left + (buttonPos.width / 2)) > windowCenter) {
                    this.activeContainer.style.right = "0px";
                    this.activeContainer.style.left = "auto";
                }
            }

        }
    }

    allMultiCtaButtons.forEach(function (button) {

        button.addEventListener("click", function (event) {
            event.preventDefault();

            if (multiCtaObject.activeButton) {

                if (event.target === multiCtaObject.activeButton) {
                    multiCtaObject.closeMultiCtaContainers();
                    multiCtaObject.resetActiveMultiCtaButtons();
                } else {
                    multiCtaObject.closeMultiCtaContainers();
                    multiCtaObject.resetActiveMultiCtaButtons();
                    multiCtaObject.activeButton = event.target;

                    event.target.classList.add("active");
                    const id = button.dataset.ctaId;
                    const name = `multictacontainer_${id}`;
                    const el = document.getElementById(name);
                    multiCtaObject.activeContainer = el;
                    multiCtaObject.updateActiveContainerPosition();
                }
            } else {
                event.target.classList.add("active");
                multiCtaObject.activeButton = event.target;
                const id = button.dataset.ctaId;
                const name = `multictacontainer_${id}`;
                const el = document.getElementById(name);
                multiCtaObject.activeContainer = el;
                multiCtaObject.updateActiveContainerPosition();
            }

        });
    });


    allPseudoMultiCtaButtons.forEach(function (link) {
        //re-delegate the click event on to the actual button
        link.addEventListener("click", function (event) {
            event.preventDefault();
            const wrapper = event.target.closest(".ticket-passes-comparison-list__item");
            const id = link.dataset.ctaId;
            const b = wrapper.querySelector(`button[data-cta-id="${id}"]`);
            b.click();
        });

    });

    document.addEventListener("click", function (event) {

        if (event.target.dataset.multictabutton && event.target.dataset.multictabutton === "true") {
            multiCtaObject.updateActiveContainerPosition();
            return;
        }

        if (!event.target.closest(".multicta-container") && !event.target.closest(".four-column-gallery-block-item")) {
            multiCtaObject.closeMultiCtaContainers();
        }
    });

    window.addEventListener("resize", function () {
        multiCtaObject.updateActiveContainerPosition();
    });
    
    window.addEventListener("scroll", function() {
        multiCtaObject.updateActiveContainerPosition();
    });

});