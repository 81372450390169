window.addEventListener("DOMContentLoaded", () => {


    function initSimpleDropDowns() {

        const allDropdowns = $(".js-simple-dropdown");
        const root = document.querySelector("html");
        const mobileMenuButton = $(".accessible-megamenu-toggle");

        function closeAllDropDowns() {
            allDropdowns.find("button[aria-expanded]").removeClass("selected").attr("aria-expanded", "false");

            allDropdowns.find("button").removeClass("direction-up").removeClass("direction-down").removeClass("direction-left").removeClass("direction-right");
        }

        allDropdowns.each(function () {

            const dropdown = $(this);
            const otherdropdowns = allDropdowns.not(dropdown);
            const but = dropdown.find("button[aria-expanded]");
            const dd = but.next().get(0);//convert to vanilla js DOM node

            but.on("click", function (e) {

                //If the main mobile menu is open, AND we are clicking on the mobile book now button, then close the main menu
                if (root.classList.contains("mobile-menu-open") && e.target.getAttribute("data-book-now-button") !== null) {
                    mobileMenuButton.trigger("click");
                }


                const aria = but.attr("aria-expanded") === "true" ? "false" : "true";
                but.toggleClass("selected").attr("aria-expanded", aria);
                otherdropdowns.find("button[aria-expanded]").removeClass("selected");
                otherdropdowns.find("button[aria-expanded]").attr("aria-expanded", "false");

                 if (!but.hasClass("book-now-button")) {
                    //book-now-button is used as the fly in menu from the left, and we dont want to add classes below to this button

                    var isOut = isOutOfViewport(dd);

                    //Also take into account that there may be a bookingbar at the bottom om the page
                    var bookingBar = document.querySelectorAll(".hex-footer-booking-bar-wrapper, .booking-container");
                    var bookingBarCheck = {};
                    bookingBarCheck.bottom = false;

                    var bookingBarBounding = dd.getBoundingClientRect();

                    if (bookingBar && bookingBar.length > 0) {
                        bookingBarCheck.bottom = (bookingBarBounding.bottom + bookingBar[0].clientHeight) > (window.innerHeight || document.documentElement.clientHeight);
                    }

                    if(dropdown.attr('data-use-scroll') === 'true') {
                        const subDrop = dropdown[0].querySelector('.simple-dropdown');

                        if (isOut.bottom || bookingBarCheck.bottom) {
                            const space = window.innerHeight - ((dropdown[0].offsetTop - window.scrollY) + dropdown[0].offsetHeight) - bookingBar[0].clientHeight;
                            subDrop.style.maxHeight = space - 5 + 'px';
                        } else {
                            subDrop.style.maxHeight = 'none';
                        }
                    } else {
                        if (isOut.top) {
                            // Top is out of viewport
                            but.addClass("direction-down");
                        }

                        if (isOut.left) {
                            // Left side is out of viewport
                            but.addClass("direction-left");
                        }

                        if (isOut.bottom || bookingBarCheck.bottom) {
                            // Bottom is out of viewport
                            but.addClass("direction-up");

                        }

                        if (isOut.right) {
                            // Right side is out of viewport
                            but.addClass("direction-right");
                        }
                    }
                }
            });
        });

        $(document).on("click", function (e) {
            if ($(e.target).closest(".js-simple-dropdown").length === 0) {
                closeAllDropDowns();
            }
        });


        $(document).on("click", function (e) {
            if ($(e.target).hasClass("js-close-simple-dropdown")) {
                closeAllDropDowns();
            }
        });


        $(document).on("keydown", function (e) {
            const key = event.key; // const {key} = event; in ES6+

            if (key === "Escape") {
                closeAllDropDowns();
            }
        });



    }

    initSimpleDropDowns();
});