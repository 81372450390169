import Cookies from 'js-cookie';

window.addEventListener("DOMContentLoaded", () => {

  function initAlertBanner() {

    const alertBanner = document.querySelector("[data-alert-banner]");
    const siteConfig = {};
    Object.assign(siteConfig, window.merlinConfig);

    if (!alertBanner) {
      return;
    }

    document.documentElement.style.scrollPaddingTop = 110 + alertBanner.clientHeight + "px";
    const alertBannerButton = document.querySelector("[data-alert-banner-button]");
    let timeToLive = alertBanner.getAttribute("data-ttl");


    if (timeToLive === "") {
      timeToLive = 1;
    } else {
      timeToLive = parseInt(timeToLive);
    }

    alertBannerButton.addEventListener("click", (e) => {
      Cookies.set('merlin_hide-alert-banner', 'true', { expires: timeToLive, path: siteConfig.urlPrefix });
      alertBanner.parentNode.removeChild(alertBanner);
      document.documentElement.style = "";
    });
  }

  initAlertBanner();
});