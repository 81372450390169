const solidTabsBlockAccordion = {

    init: function () {

        document.addEventListener("click", function (e) {
            if (e.target.classList.contains("solid-tabs-block-accordion__item__button")) {

                const aria = e.target.getAttribute("aria-expanded") === "true" ? "false" : "true";
                e.target.classList.toggle("selected");
                e.target.setAttribute("aria-expanded", aria);
                e.target.nextElementSibling.classList.toggle("open");

            }
        });

    }
};

window.addEventListener("DOMContentLoaded", event => {
    solidTabsBlockAccordion.init();
});