document.addEventListener("DOMContentLoaded", () => {

    var tables = (function () {

        function init() {

            var responsiveTables = $(".rich-text table");

            if (responsiveTables.length <= 0)
                return;

            let template = "";
            template += `<div class="show-overlay" aria-hidden="true">
					<svg width="64" height="64" viewBox="0 0 32 32" aria-hidden="true" focusable="false">
						<path class="hands-off" d="M22 15a2 2 0 0 0-4 0M14 14a2 2 0 0 1 4 0v3" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                        <path class="hands-off" d="M14 17V6a2 2 0 0 0-4 0v11l-3-5a2 2 0 0 0-2.828 2.828S6 17 6 19a10 10 0 0 0 20 0v-3a2 2 0 0 0-4 0v1" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
					</svg>
				</div>`;


            var overlay = $(template);

            function handleTableOverlay(table) {

                if (window.detectTouchscreen()) {

                    var parent = table.parent();

                    if (table.width() > parent.width()) {

                        parent.append(overlay.clone());

                        parent.on("click touchstart", function () {
                            parent.find(".show-overlay").remove();
                        });

                    }
                }
            }




            responsiveTables.each(function () {

                var table = $(this);
                table.removeAttr("border");

                if (table.parent().hasClass("resp-table")) {
                    return;//Never wrap table more than once 
                }

                table.wrap("<div class='resp-table'></div>");

                handleTableOverlay(table);
            });
        }

        return {
            init: init
        }
    })();

    tables.init();

});
