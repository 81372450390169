const siteConfig = {};
Object.assign(siteConfig, window.merlinConfig);

document.addEventListener("DOMContentLoaded", function () {

	const buttons = document.querySelectorAll(".ticket-passes-comparison-list__item__cta[data-id]");

	buttons.forEach(button => {
		button.addEventListener("click", function() {
			setUrgency(parseInt(button.dataset.id));
		});
	});

	buttons.forEach(button => {
		getUrgency(
			parseInt(button.dataset.id),
			button.dataset.timespan,
			button.dataset.thresholdmin,
			button.dataset.thresholdmax,
			button
		);
	});
});

function setUrgency(id) {
	$.ajax({
		url: siteConfig.urlPrefix+ "/umbraco/api/urgency/set",
		method: "Get",
		data: {
			id: id
		},
		success: function(data) {},
		fail: function(data) {
			console.log("error", data);
		}
	});
}

function getUrgency(id, timespan, thresholdMin, thresholdMax, button) {
	$.ajax({
		url: siteConfig.urlPrefix +"/umbraco/api/urgency/get",
		method: "Get",
		data: {
			id: id,
			minutes: timespan
		},
		success: function (data) {

			if (typeof data.cnt === "undefined") {
				console.log("the 'data.cnt' property is not available...");
                button.parentElement.querySelector(
                    ".ticket-passes-comparison-list__item__social-proof"
                ).style.display = "none";
            }

			else {

                if (data.cnt >= thresholdMin && data.cnt <= thresholdMax) {
                    if (
                        button.parentElement.dataset.number == "0" ||
                            button.parentElement.dataset.number == "1"
                    ) {
                        const wrapper = document.querySelector(
                            ".ticket-passes-comparison-list-wrapper"
                        );
                        wrapper.classList.add("social-proof-margin");
                    }
                    button.parentElement.querySelector(
                        ".ticket-passes-comparison-list__item__social-proof .noOfPeople"
                    ).innerText = data.cnt;
                    button.parentElement.querySelector(
                        ".ticket-passes-comparison-list__item__social-proof"
                    ).style.display = "flex";
                } else if (data.cnt >= thresholdMin && data.cnt > thresholdMax) {
                    if (
                        button.parentElement.dataset.number == "0" ||
                            button.parentElement.dataset.number == "1"
                    ) {
                        const wrapper = document.querySelector(
                            ".ticket-passes-comparison-list-wrapper"
                        );
                        wrapper.classList.add("social-proof-margin");
                    }
                    button.parentElement.querySelector(
                        ".ticket-passes-comparison-list__item__social-proof .noOfPeople"
                    ).innerText = thresholdMax + "+";
                    button.parentElement.querySelector(
                        ".ticket-passes-comparison-list__item__social-proof"
                    ).style.display = "flex";
                } else {
                    button.parentElement.querySelector(
                        ".ticket-passes-comparison-list__item__social-proof"
                    ).style.display = "none";
                }
		    }



		}
	});
}
