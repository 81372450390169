document.addEventListener("DOMContentLoaded", function () {
    var merlintoolbox = (function () {
        function init() {
            $(document).on('click', '.merlintoolbox-btn', function (e) {
                setTheme($(this).data('theme-brand'),$(this).data('theme-site'));
            });
        }
        return {
            init: init
        }
    })();
    merlintoolbox.init();
	
});

function setTheme(brand, site) {
    const siteConfig = {};
    Object.assign(siteConfig, window.merlinConfig);
	
    let payload = {};
    payload.Site = site;
    payload.Brand = brand;

    $.ajax({
        url: `${siteConfig.urlPrefix}/umbraco/api/theme/set`,
        method: "POST",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        data: JSON.stringify(payload)
    }).done(function (data) {
        //do nothing
        location.reload();
    }).fail(function (data) {
        console.log("error", data);
    }).always(function () {
        //do nothing
    });
}

function URL_add_parameter(url, param, value) {
    var hash = {};
    var parser = document.createElement('a');

    parser.href = url;

    var parameters = parser.search.split(/\?|&/);

    for (var i = 0; i < parameters.length; i++) {
        if (!parameters[i])
            continue;

        var ary = parameters[i].split('=');
        hash[ary[0]] = ary[1];
    }

    hash[param] = value;

    var list = [];
    Object.keys(hash).forEach(function (key) {
        list.push(key + '=' + hash[key]);
    });

    parser.search = '?' + list.join('&');
    return parser.href;
}
